<template>
  <ZMainPage>
    <template #footer>
      <el-button :loading="loading" type="primary" @click="handleSubmit('form')">保存</el-button>
      <el-button :disabled="loading" @click="handleBack">取消</el-button>
    </template>
    <el-form :model="form" :rules="rules" ref="form" label-width="200rem">
      <el-form-item label="学校正式名称" prop="name">
        <el-input v-model="form.name" class="independentStyle" maxlength="28" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="当地俗称" prop="local_name">
        <el-input placeholder="请输入" maxlength="50" v-model="form.local_name"></el-input>
      </el-form-item>
      <el-form-item label="学校识别码" prop="school_code">
        <el-input placeholder="请输入" maxlength="50" v-model="form.school_code"></el-input>
      </el-form-item>
      <el-form-item label="学段" prop="level">
        <el-checkbox-group v-model="form.level">
          <el-checkbox v-for="item in levelOptions" :label="item.label" :disabled="item.disabled">{{ item.text }}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="办学类型" prop="nature">
        <el-radio-group v-model="form.nature">
          <el-radio :label="1">公办</el-radio>
          <el-radio :label="2">民办</el-radio>
          <el-radio :label="3">民办公助</el-radio>
        </el-radio-group>
      </el-form-item>
      <div class="flex">
        <el-form-item label="学校地址" prop="province">
          <el-select v-model="form.province" placeholder="选择省份" @change="getCity">
            <el-option v-for="item in provinceOptions" :label="item.address_name" :value="item.address_code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="city" label-width="20rem">
          <el-select v-model="form.city" placeholder="选择城市" @change="getArea">
            <el-option v-for="item in cityOptions" :label="item.address_name" :value="item.address_code"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="" prop="area" label-width="20rem">
          <el-select v-model="form.area" placeholder="选择区/县">
            <el-option v-for="item in areaOptions" :label="item.address_name" :value="item.address_code"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item label="" prop="address">
        <el-input style="width: 790rem !important;" placeholder="请输入详细地址" v-model="form.address"></el-input>
      </el-form-item>
      <el-form-item label="校长姓名" prop="principal">
        <el-input placeholder="请输入" v-model="form.principal"></el-input>
      </el-form-item>
      <el-form-item label="校长联系电话" prop="mobile">
        <el-input placeholder="请输入" maxlength="20" v-model="form.mobile"></el-input>
      </el-form-item>
      <el-form-item label="负责渠道专员">
        <el-select
          v-model="form.channel"
          filterable
          remote
          reserve-keyword
          placeholder="请搜索"
          :remote-method="requestSchool"
          :loading="searchLoading">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <div class="flex align-center" v-if="isShowScore">
        <el-form-item label="参考录取分数线" prop="min_score">
          <el-input placeholder="请输入" v-model="form.min_score"></el-input>
        </el-form-item>
        <span style="margin-bottom: 22rem;margin-left: 20rem;">-</span>
        <el-form-item label="" prop="max_score" label-width="20rem">
          <el-input placeholder="请输入" v-model="form.max_score"></el-input>
        </el-form-item>
      </div>
      <el-form-item label="备注" prop="remark">
        <el-input placeholder="请输入" v-model="form.remark" show-word-limit="" maxlength="300" rows="8" type="textarea" resize="none" style="width: 790rem;"></el-input>
      </el-form-item>
    </el-form>
  </ZMainPage>
</template>

<script>
import { changeOption } from '@/utils'
import { validateNumber } from '@/utils/validate'
import { formDataBackMixin } from '@/utils/mixins'
import http from '@/api'

export default {
  _config:{route:[{path:'add',meta:{title:'新增'}},{path:'edit',meta:{title:'编辑'}}]},
  mixins: [formDataBackMixin],
  data() {
    const validatorFunc = (value, callback) => validateNumber(value, callback, { min: 0, max: 10000, equalToMin: true, equalToMax: true })
    const validatorMin = (_, value, callback) => {
      if (this.isChecked1) {
        if (this.form.min_score === '' && this.form.max_score === '') {
          callback()
          if (this.isChecked2) {
            this.isChecked1 = false
            this.isChecked2 = false
            return
          }
          this.$refs.form.validateField('max_score')
          return
        }
        if (value === '') return callback(new Error('请输入最低分'))
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          callback(new Error('参考录取分数线最高分需大于最低分'))
        } else {
          validatorFunc(value, callback)
        }
        if (this.isChecked2) {
          this.isChecked1 = false
          this.isChecked2 = false
          return
        }
        this.$refs.form.validateField('max_score')
        return
      }
      if (this.form.min_score === '' && this.form.max_score === '') {
        callback()
        if (this.isChecked2) {
          this.isChecked2 = false
          this.$refs.form.validateField('max_score')
        }
      } else if (this.form.min_score === '' && this.form.max_score) {
        callback(new Error('请输入最低分'))
      } else if (this.form.min_score && this.form.max_score === '') {
        this.$refs.form.validateField('max_score')
        validatorFunc(value, callback)
      } else  {
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          return callback(new Error('参考录取分数线最高分需大于最低分'))
        }
        validatorFunc(value, callback)
        if (!this.isChecked2) {
          this.isChecked2 = true
          this.$refs.form.validateField('max_score')
        }
      }
    }
    const validatorMax = (_, value, callback) => {
      if (this.isChecked2) {
        if (this.form.min_score === '' && this.form.max_score === '') {
          callback()
          if (this.isChecked1) {
            this.isChecked1 = false
            this.isChecked2 = false
            return
          }
          this.$refs.form.validateField('min_score')
          return
        }
        if (value === '') return callback(new Error('请输入最高分'))
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          callback(new Error('参考录取分数线最高分需大于最低分'))
        } else {
          validatorFunc(value, callback)
        }
        if (this.isChecked1) {
          this.isChecked1 = false
          this.isChecked2 = false
          return
        }
        this.$refs.form.validateField('min_score')
        return
      }
      if (this.form.min_score === '' && this.form.max_score === '') {
        callback()
        if (this.isChecked1) {
          this.isChecked1 = false
          this.$refs.form.validateField('min_score')
        }
      } else if (this.form.min_score === '' && this.form.max_score) {
        this.$refs.form.validateField('min_score')
        validatorFunc(value, callback)
      } else if (this.form.min_score && this.form.max_score === '') {
        callback(new Error('请输入最高分'))
      } else  {
        if (Number(this.form.min_score) >= Number(this.form.max_score)) {
          return callback(new Error('参考录取分数线最高分需大于最低分'))
        }
        validatorFunc(value, callback)
        if (!this.isChecked1) {
          this.isChecked1 = true
          this.$refs.form.validateField('min_score')
        }
      }
    }
    return {
      id: '',
      searchLoading: false,
      options: [],
      isChecked1: false,
      isChecked2: false,
      //办学类型配置
      levelOptions: [
        {label: 3, text: '高中', disabled: false},
        {label: 2, text: '初中', disabled: false},
        {label: 1, text: '小学', disabled: false},
      ],
      form: {
        name: '',
        local_name: '',
        school_code: '',
        level: [],
        nature: 1,
        province: '',
        city: '',
        area: '',
        address: '',
        principal: '',
        mobile: '',
        channel: '',
        min_score: '',
        max_score: '',
        remark: ''
      },
      rules: {
        name: [{required: true, message: "请输入学校正式名称"}],
        // school_code: [{required: true, message: "请输入学校识别码"}],
        level: [{required: true, message: "请选择办学类型"}],
        nature: [{required: true, message: "请选择学校举办者类型"}],
        province: [{required: true, message: "请选择学校所在省份"}],
        city: [{required: true, message: "请选择学校所在城市"}],
        area: [{required: true, message: "请选择学校所在区/县"}],
        min_score: [{ required: false, validator: validatorMin, trigger: 'change' }],
        max_score: [{ required: false, validator: validatorMax, trigger: 'change' }]
      },
      provinceOptions: [],
      cityOptions: [],
      areaOptions: [],
    }
  },
  computed: {
    isShowScore() {
      return this.form.level.length === 1 && this.form.level[0] === 3
    }
  },
  created() {
    this.getProvince()
    const id = this.$route.query.id
    if(id) {
      this.id = id
      this.getData(id)
    }
  },
  methods: {
    requestSchool(name) {
      if (!name) return this.options = []
      this.searchLoading = true
      http({ url: '/recruit/school/get-account-list', pre: 'zs' }, { keyword: name }).then(res => {
        this.options = changeOption({ list: res, label: 'name', value: 'id' })
      }).finally(() => this.searchLoading = false)
    },
    getData(id) {
      http({ url: `/recruit/school/${id}`, pre: 'zs' }).then(res => {
        const data = {name: '', local_name: '', school_code: '', level: [], nature: 1, province: '', city: '', area: '', address: '', principal: '', mobile: '', channel: '', min_score: '', max_score: '', remark: ''}
        Object.keys(data).forEach(key => {
          data[key] = res[key]
        })
        if(!data.max_score) data.max_score = ''
        if(!data.min_score && !data.max_score) data.min_score = ''
        if(!data.channel) data.channel = ''
        this.levelOptions.forEach(item => {
          if(data.level.includes(item.label)) item.disabled = true
        })
        this.requestSchool(res.channel_text)
        this.getCity(data.province)
        this.getArea(data.city)
        this.form = data
        this.isChecked2 = true
      })
    },
    //获取省份
    getProvince(){
      this.$_register('/api/recruit/common/area',{params:{address_code: '000000'}}).then(res => {
        this.provinceOptions = res.data.data
      })
    },

    getCity(e){
      this.form.city = ''
      this.form.area = ''
      this.areaOptions = []
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.cityOptions = res.data.data
      })
    },

    getArea(e){
      this.form.area = ''
      this.$_register('/api/recruit/common/area',{params:{address_code: e}}).then(res => {
        this.areaOptions = res.data.data
      })
    },

    // 提交数据
    handleSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const { max_score, min_score, ...other } = this.form
          const data = {...other }
          if (this.isShowScore) {
            data.min_score = min_score
            data.max_score = max_score
          }
          const url = `/recruit/school${this.id ? '/' + this.id : ''}`
          http({ url, pre: 'zs', method: 'post' }, data).then(() => {
            this.$message({ type: 'success', message: `${this.id ? '编辑' : '添加'}成功` })
            this.$store.commit('setPage', 1);
            this.$router.back();
          })
        }
      })
    },
    handleBack() {
      const { name, level, nature, province, city, area } = this.form
      const data = { name, level, nature, province, city, area }
      this.formBack(data, ['nature'])
    }
  },
}
</script>

<style lang="scss" scoped>

.form {
	padding-top: 70rem;
}

::v-deep .el-form .el-input {
	min-width: 250rem;
}

@media only screen and (min-width: 1140rem) {
	.el-form .el-input {
		width: auto !important;
		/* min-width: 250rem; */
		max-width: 100%;
	}
}


@media only screen and (min-width: 1140rem) {
	.el-form .independentStyle.el-input {
		width: 30.13888888888888vw !important;
		/*min-width:250rem;*/
		max-width: 100%;
	}
}

@media only screen and (max-width: 1139rem) {
	.el-form .independentStyle.el-input {
		width: 500rem !important;
		max-width: 100%;
	}
}
</style>
